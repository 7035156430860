import React from "react"
import LayoutDay from "../../../components/Chronology/LayoutDay"
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft"
import ContentRight from "../../../components/Chronology/Modules/ContentRight"
import ModBuscandoDatos from "../../../components/Chronology/Modules/ModBuscandoDatos"
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable"
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight"
import ModDailyHighlight from "../../../components/Chronology/Modules/ModDailyHighlight"
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu"
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine"
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu"
import ModText from "../../../components/Chronology/Modules/ModText"
import { StrongWhite } from "../../../components/Textstyles/Styles"

export const frontmatter = {
  title: "Día 104",
  week: "Semana 15",
  day: "25",
  month: "Jun",
  monthNumber: "06",
  date: "2020-06-25",
  path: "/cronologia/semana-15#dia-25-jun/",
}

const Day104 = props => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
        <ModCovidHighlight>Portugal vuelve a confinar Lisboa por rebrotes</ModCovidHighlight>
      </ContentLeft>

      <ContentRight>
        <ModText>
          En los últimos siete días se han diagnosticado en <strong>España</strong> 1.760 casos
          mediante pruebas PCR. En ese mismo periodo, 146 personas han requerido hospitalización
          (8,3% de los diagnósticos), de los que 14 han sido ingresos en la UCI, y se han producido
          11 fallecimientos.
        </ModText>
        <ModDailyHighlight>
          Se detecta un <StrongWhite>fuerte rebrote</StrongWhite> en la provincia de Huesca
        </ModDailyHighlight>

        <ModBuscandoDatos num="random" />
      </ContentRight>
    </LayoutDay>
  )
}
export default Day104
